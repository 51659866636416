import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { format } from 'date-fns'
import { dayOfWeek, DOT_DATE_FORMAT } from '../../../../../shared/constants/date'
import styles from './AccordionDetails.module.scss'
import StockCard from '../StockCard/StockCard'
import { useBaseQuantityFormContext } from '../../state/BaseQuantityFormState'
import { useOrdersEstimatesQuery } from '../../queries'
import { TOrderItemForm } from '../../types'
import { useGetUnit } from '../../hooks/useGetUnit'
import { useSellerId } from '../../hooks/useSellerId'
import { dateFromDotDateFormat } from '../../../../../shared/utils/date'
import AccordionHandlers from '../AccordionHandlers/AccordionHandlers'
import useCurrentOrder from '../../hooks/useCurrentOrder'

const DEFAULT_PRICE_PER_CU_VALUE = '0.00'

function checkToNumber(value?: string | number) {
  if (value === '') {
    return false
  }
  return value !== undefined
}

function countQuantity(weeklyOrderItem: TOrderItemForm, orderIndex: number) {
  const {
    amountUnitPerCu,
    amountCuPerTu,
    orders
  } = weeklyOrderItem
  const amountKg = orders[orderIndex].quantityTu

  if (
    !checkToNumber(amountUnitPerCu)
        || !checkToNumber(amountKg)
        || !checkToNumber(amountCuPerTu)
  ) {
    return undefined
  }

  return Number(amountUnitPerCu) * Number(amountCuPerTu) * Number(amountKg)
}

function useCardsData(quantityIndex: number) {
  const {
    growingPlanId,
    weekDays,
    formReturn: { watch }
  } = useBaseQuantityFormContext()
  const formData = watch()
  const weeklyOrderItem = formData.orderItems[quantityIndex] as TOrderItemForm
  const preparedSellerId = useSellerId()
  const { data: ordersEstimatesData } = useOrdersEstimatesQuery(
    weekDays,
    growingPlanId,
    weeklyOrderItem?.productId,
    weeklyOrderItem?.labelId,
    preparedSellerId
  )

  return useMemo(
    () => {
      const quantities = weeklyOrderItem?.orders
        .map(({ quantityTu }, index) => countQuantity(weeklyOrderItem, index))

      return ordersEstimatesData?.map((item, index) => {
        const diff = quantities
          .slice(0, index + 1)
          .reduce<number>((result, current) => result + (Number(current) || 0), 0)

        return {
          date: item.date,
          estimate: item.estimate,
          quantity: quantities[index],
          stockAmount: item.stockAmount - diff
        }
      })
    },
    [formData, ordersEstimatesData]
  )
}

function useOnceFillPricePerCUs(quantityIndex: number) {
  const {
    formReturn: { setValue },
    weekDays,
    onlyDay,
    fieldArrayReturn: { fields }
  } = useBaseQuantityFormContext()

  const isPricePerCUsFillAble = useMemo(() => fields[quantityIndex]
    .orders
    .every(({ pricePerCu }) => pricePerCu === DEFAULT_PRICE_PER_CU_VALUE), [quantityIndex])

  const [
    pricePerCuDirty,
    setPricePerCuDirty
  ] = useState<{ isPricePerCuDirty: boolean, pricePerCuValue: string }>()

  useEffect(() => {
    if (!onlyDay && isPricePerCUsFillAble && pricePerCuDirty?.isPricePerCuDirty) {
      weekDays.forEach((_, orderIndex) =>
        setValue(`orderItems[${quantityIndex}].orders[${orderIndex}].pricePerCu`, pricePerCuDirty.pricePerCuValue))
    }
  }, [pricePerCuDirty?.isPricePerCuDirty, quantityIndex])

  return useCallback((value: string, result: string) => {
    if (value && value !== '0' && !pricePerCuDirty) {
      setPricePerCuDirty({ isPricePerCuDirty: true, pricePerCuValue: result })
    }
  }, [pricePerCuDirty])
}

type TProps = {
    quantityIndex: number
    weeklyOrderId?: string
}

const AccordionDetails: FC<TProps> = ({ quantityIndex, weeklyOrderId }) => {
  const {
    formReturn,
    weekDays,
    orders,
    sellerDeliveryDays
  } = useBaseQuantityFormContext()
  const cardsData = useCardsData(quantityIndex)
  const handlePricePerCuDirty = useOnceFillPricePerCUs(quantityIndex)
  const { onlyDay } = useBaseQuantityFormContext()
  const unit = useGetUnit(quantityIndex)
  const { control } = formReturn
  const foundedOrder = useCurrentOrder({ formReturn, orders, onlyDay, quantityIndex })

  const deliveryDays = foundedOrder?.deliveryDays
    ?.map((day) => format(dateFromDotDateFormat(day), dayOfWeek).toUpperCase())

  const deliveryDates = foundedOrder?.weeklyOrderId
    ? deliveryDays
    : sellerDeliveryDays
      ?.map((dayItem) => format(dateFromDotDateFormat(dayItem), dayOfWeek).toUpperCase())

  return (
    <div className={styles.wrapper}>
      <div className={styles.stockGroup}>
        {weekDays.map((day, index) => {
          const formattedDay = format(day, dayOfWeek).toUpperCase()
          const dotDay = format(day, DOT_DATE_FORMAT)
          if (!deliveryDates?.includes(formattedDay)) {
            return <></>
          }

          const foundCardsData = cardsData?.find(
            ({ date }) => date === dotDay
          )
          const foundDailyOrder = foundedOrder?.dailyOrders
            .find(((daily) => daily.date === dotDay))
          const isDisableDay = dotDay === onlyDay
            ? !(foundDailyOrder?.editableForCurrentUser ?? true)
            : true

          const disabledStatus = onlyDay
            ? isDisableDay : !(foundedOrder?.editableForCurrentUser ?? true)

          return (
            <>
              <StockCard
                key={day.toDateString()}
                disabled={disabledStatus}
                highlight={false}
                unit={unit}
                orderIndex={index}
                handlePricePerCuDirty={handlePricePerCuDirty}
                estimate={foundCardsData?.estimate}
                stockAmount={foundCardsData?.stockAmount}
                quantity={foundCardsData?.quantity}
                quantityIndex={quantityIndex}
                control={control}
                date={day}
                dailyStatus={foundDailyOrder?.status}
              />
            </>
          )
        })}
      </div>
      <AccordionHandlers quantityIndex={quantityIndex}/>
    </div>
  )
}

export default AccordionDetails
