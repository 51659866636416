import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { apiClient } from '../../Interceptor'
import {
  TUpdateWeeklyGrowingPlanData,
  TWeeklyConfirmedParams,
  TWeeklyInitParams,
  TWeeklyInitResponse,
  TWeeklyOrderData, TWeeklyOrderDataItem
} from './types'
import { QuantityReportsQueryKeys } from '../QuantityReports/queries'

enum QueryKeys {
    OrdersWeekly = 'OrdersWeekly',
    OrdersWeeklyCopy = 'OrdersWeeklyCopy',
    WeeklyInit = 'WeeklyInit'
}

async function ordersWeekly(params: Partial<TWeeklyConfirmedParams>): Promise<TWeeklyOrderData> {
  try {
    const response = await apiClient.get('/app/orders/weekly', { params })
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useOrdersWeeklyQuery = (params: Partial<TWeeklyConfirmedParams>) =>
  useQuery<TWeeklyOrderData, Error>(
    [QueryKeys.OrdersWeekly, params],
    () => ordersWeekly(params),
    {
      enabled: !!(params.dateFrom && params.dateTo),
      cacheTime: 0
    }
  )

type TUpdateGrowingPlanMutationParams = {
    orderId: string,
    data: TUpdateWeeklyGrowingPlanData
}

async function updateWeeklyOrder(
  { orderId, data }: TUpdateGrowingPlanMutationParams
): Promise<TWeeklyOrderDataItem> {
  try {
    const res = await apiClient.put(`/app/orders/weekly/${orderId}`, data)
    return res.data
  } catch (e: any) {
    throw new Error(e)
  }
}

async function updateDraftWeeklyOrder(
  { orderId, data }: TUpdateGrowingPlanMutationParams
): Promise<TWeeklyOrderDataItem> {
  try {
    const res = await apiClient.put(`/app/orders/weekly/${orderId}/draft`, data)
    return res.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useUpdateWeeklyOrderMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<TWeeklyOrderDataItem, Error, TUpdateGrowingPlanMutationParams>(
    updateWeeklyOrder, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          [QuantityReportsQueryKeys.OrdersCalendar]
        )
        await queryClient.invalidateQueries([QueryKeys.OrdersWeekly])
      }
    }
  )
}

export const useDraftWeeklyOrderMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<TWeeklyOrderDataItem, Error, TUpdateGrowingPlanMutationParams>(
    updateDraftWeeklyOrder, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKeys.OrdersWeekly] })
        queryClient.invalidateQueries(
          [QuantityReportsQueryKeys.OrdersCalendar]
        )
      }
    }
  )
}

async function confirmWeeklyOrder(weeklyOrderId: string): Promise<void> {
  try {
    await apiClient.put(`/app/orders/weekly/${weeklyOrderId}/confirm`)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useConfirmWeeklyOrderMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<void, Error, string>(confirmWeeklyOrder, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.OrdersWeekly] })

      await queryClient.invalidateQueries(
        [QuantityReportsQueryKeys.OrdersCalendar]
      )
    }
  })
}

async function createWeeklyOrder(data: TUpdateWeeklyGrowingPlanData):
    Promise<TWeeklyOrderDataItem> {
  const { publish, ...rest } = data
  try {
    const res = await apiClient.post(`/app/orders/weekly?publish=${publish}`, rest)
    return res.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useCreateWeeklyOrderMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<TWeeklyOrderDataItem, Error, TUpdateWeeklyGrowingPlanData>(createWeeklyOrder, {
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(
        [QuantityReportsQueryKeys.OrdersCalendar]
      )

      await queryClient.invalidateQueries([QueryKeys.OrdersWeekly])
      return data
    }
  })
}

async function weeklyInit(params: TWeeklyInitParams): Promise<TWeeklyInitResponse> {
  try {
    const response = await apiClient('/app/orders/weekly/init', { params })
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export function useWeeklyInitQuery(params: TWeeklyInitParams) {
  const keys = [QueryKeys.WeeklyInit, params.growingPlanId, params?.buyerId, params?.firstWeekDate]
  return useQuery<TWeeklyInitResponse, Error>(
    [keys],
    () => weeklyInit(params),
    {
      enabled: !!params?.growingPlanId || !!params?.buyerId
    }
  )
}
