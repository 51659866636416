import { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Control, useWatch } from 'react-hook-form'
import { usePrevious } from 'react-use'
import CustomSelectControl
  from '../../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'
import { useBaseQuantityFormContext } from '../../../state/BaseQuantityFormState'
import { TOrderItemForm } from '../../../types'
import { useProductsQuery } from '../../../queries'
import { findExistingProductLabels } from '../../../utils/generateFormData'
import { useSellerId } from '../../../hooks/useSellerId'
import { combineGrowingPlanProducts } from '../../../utils/prepareGrowingPlanProductData'
import { usePrepareLabelSelectOption } from '../../../hooks/usePrepareLabelSelectOption'

function useFilteredOptions(control: Control<any>, quantityIndex: number) {
  const { buyerId, onlyDay, growingPlanId, date } = useBaseQuantityFormContext()
  const { t } = useTranslation()
  const preparedSellerId = useSellerId()
  const orderItems = useWatch({ control, name: 'orderItems' }) as TOrderItemForm[]
  const prepareOption = usePrepareLabelSelectOption()
  const { productId, labelId } = orderItems?.[quantityIndex]

  const existingProductLabels = useMemo(
    () => findExistingProductLabels(orderItems, productId)
      .filter((existingLabelId) => existingLabelId !== labelId),
    [orderItems, productId]
  )

  const {
    data: products
  } = useProductsQuery(growingPlanId, preparedSellerId, date, buyerId, onlyDay)

  return useMemo(
    () => {
      const found = combineGrowingPlanProducts(products).find(
        (item) => item.productId === productId
      )

      const growingPlanLabelList = prepareOption(found?.gpLabels ?? [], existingProductLabels)
      const noGrowingPlanLabelList = prepareOption(found?.noGpLabels ?? [], existingProductLabels)

      return [
        ...(growingPlanLabelList?.length ? [{
          groupName: t('quantity.selects.product.inGrowingPlan'),
          options: growingPlanLabelList
        }] : []),
        ...(noGrowingPlanLabelList?.length ? [{
          groupName: t('quantity.selects.product.notInGrowingPlan'),
          options: noGrowingPlanLabelList
        }] : [])
      ]
    },
    [products, productId, existingProductLabels]
  )
}

function useResetLabel(quantityIndex: number) {
  const { formReturn: { watch, resetField } } = useBaseQuantityFormContext()
  const product = watch(`orderItems[${quantityIndex}].productId`)
  const prevProduct = usePrevious(product)
  useEffect(() => {
    if (!prevProduct) {
      return
    }
    resetField(`orderItems[${quantityIndex}].labelId`)
  }, [product])
}

type TProps = {
  disabled: boolean
  quantityIndex: number
  control: Control<any>
}
const LabelSelect: FC<TProps> = ({ control, quantityIndex, disabled }) => {
  const { t } = useTranslation()
  const product = useWatch({ control, name: `orderItems[${quantityIndex}].productId` })
  useResetLabel(quantityIndex)
  const options = useFilteredOptions(control, quantityIndex)

  return (
    <CustomSelectControl
      control={control}
      groupedOptions={options}
      isRequired
      disabled={disabled || !product}
      label={`${t('quantity.selects.label.label')} *`}
      placeholder={t('quantity.selects.label.placeholder') as string}
      name={`orderItems[${quantityIndex}].labelId`}
      handleError={(error) => (error as any)?.orderItems?.[quantityIndex]?.labelId}
    />
  )
}

export default LabelSelect
