import { Control } from 'react-hook-form'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './ActiveAccordionSummary.module.scss'
import { useBaseQuantityFormContext } from '../../state/BaseQuantityFormState'
import ProductSelect from '../quantityFields/productSelect/ProductSelect'
import CustomerUnitSelect from '../quantityFields/customerUnitSelect/CustomerUnitSelect'
import TradeInSelect from '../quantityFields/tradeInSelect/TradeInSelect'
import LogisticSelect from '../quantityFields/logisticSelect/LogisticSelect'
import LabelSelect from '../quantityFields/labelSelect/LabelSelect'
import AmountInput from '../quantityFields/amountInput/AmountInput'
import { useGetUnit } from '../../hooks/useGetUnit'
import { useValidationRules } from '../../../../../shared/hooks/useValidationRules'
import StatusTag from '../StatusTag/StatusTag'
import useExpiredDateCheck from '../../hooks/usePastDateCheck'
import { dotDateFormat } from '../../../../../shared/utils/date'
import QuantityRemove from '../QuantityRemove/QuantityRemove'
import useCurrentOrder from '../../hooks/useCurrentOrder'

type TProps = {
  control: Control<any>
  icon: JSX.Element
  quantityIndex: number
  weeklyOrderId?: string
}
const ActiveAccordionSummary: FC<TProps> = ({ control, quantityIndex, icon, weeklyOrderId }) => {
  const { t } = useTranslation()
  const {
    isSeller,
    readOnly,
    onlyDay,
    date,
    disabledProductModels,
    formReturn,
    orders
  } = useBaseQuantityFormContext()
  const { requiredRule } = useValidationRules()
  const unit = useGetUnit(quantityIndex)
  const formData = formReturn.getValues()
  const isDisabledProduct = useMemo(() => {
    const { productId, labelId } = formData.orderItems[quantityIndex]
    return !!disabledProductModels
      ?.find(({ productId: modelProductId, labelId: modelLabelId }) => (
        modelProductId === productId && labelId === modelLabelId
      ))
  }, [disabledProductModels, formReturn.getValues, quantityIndex])

  const foundedOrder = useCurrentOrder({ formReturn, orders, onlyDay, quantityIndex })

  const currentOrder = onlyDay
    ? foundedOrder?.dailyOrders?.find((daily) => daily.date === onlyDay)
    : foundedOrder

  const isControlsDisabled = (!!onlyDay
          || !!readOnly
          || (!currentOrder?.editableForCurrentUser ?? true)
          || !isSeller)
      && isDisabledProduct

  const approverName = !currentOrder?.editableForCurrentUser
    ? currentOrder?.approvals?.find((approval) => approval.status === 'PENDING')?.approver?.name
    : ''

  const isAvailableDate = useExpiredDateCheck({ type: onlyDay ? 'daily' : 'weekly', date: dotDateFormat(date!) })

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.iconWrap}>
          {icon}
        </div>
        <div>
          <div className={styles.formField}>
            <ProductSelect
              disabled={isControlsDisabled}
              quantityIndex={quantityIndex}
              control={control}/>
            <CustomerUnitSelect
              disabled={isControlsDisabled}
              quantityIndex={quantityIndex}
              control={control}/>
            <TradeInSelect
              disabled={isControlsDisabled}
              quantityIndex={quantityIndex}
              control={control}/>
            <LogisticSelect
              disabled={isControlsDisabled}
              quantityIndex={quantityIndex}
              control={control}/>
            <LabelSelect
              disabled={isControlsDisabled}
              quantityIndex={quantityIndex}
              control={control}/>
            <div>
              <AmountInput
                disabled
                quantityIndex={quantityIndex}
                control={control}
                name="amountUnitPerCu"
                label={`${t('quantity.inputLabelTest.amountCU', { unit })}`}/>
            </div>
            <div>
              <AmountInput
                disabled={isControlsDisabled}
                quantityIndex={quantityIndex}
                control={control}
                name="amountCuPerTu"
                rules={{ required: requiredRule() }}
                label={`${t('quantity.inputLabel.amountTU')} *`}/>
            </div>
            <div>
              <AmountInput
                disabled={isControlsDisabled}
                quantityIndex={quantityIndex}
                control={control}
                rules={{ required: requiredRule() }}
                name="amountTuPerLu"
                label={`${t('quantity.inputLabel.amountLU')} *`}/>
            </div>
          </div>
          <div className={styles.tagWrapper}>
            <StatusTag
              status={currentOrder?.status}
              isDaily={!!onlyDay}
              approverName={approverName}
              isDateAvailable={isAvailableDate}/>
          </div>
        </div>
      </div>
      {!onlyDay && foundedOrder?.weeklyOrderId && isAvailableDate && currentOrder?.status === 'DRAFT' && (
        <QuantityRemove
          quantityIndex={quantityIndex}
          weeklyOrderId={foundedOrder.weeklyOrderId}/>
      )}
    </div>
  )
}

export default ActiveAccordionSummary
