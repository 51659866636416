import { UseFormReturn } from 'react-hook-form'
import { TWeeklyOrderDataItem } from '../../WeeklyReport/types'

interface Props {
    formReturn: UseFormReturn<any>
    quantityIndex: number
    orders?: TWeeklyOrderDataItem[]
    onlyDay?: string
}

const useCurrentOrder = ({ formReturn, orders, quantityIndex, onlyDay }: Props)=> {
  const formData = formReturn.getValues()
  const weeklyOrder = orders?.find((order) =>
    order.weeklyOrderId === formData.orderItems[quantityIndex]?.weeklyOrderId)
  return weeklyOrder
}

export default useCurrentOrder
