import { TForm } from '../../BaseGrowingPlanWorkShop'

export function prepareGrowingPlan(data: TForm) {
  return {
    ...data,
    productPlans: data.productPlans.map(({ isOrderExist, ...rest }) =>
      ({ ...rest,
        values: rest.values.map((value) =>
          ({ ...value, labelId: value?.labelId ?? value.label?.id })) }))
  }
}
