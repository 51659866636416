import { format } from 'date-fns'
import { DOT_DATE_FORMAT } from '../../../../shared/constants/date'
import { TOrderItemForm } from '../types'
import { weekDays } from '../../../../shared/utils/date'

export function currentWeekDays(date?: Date) {
  if (!date) {
    return []
  }

  return weekDays(date)
}

export const generateFormData = (date?: Date) => {
  const test: TOrderItemForm = {
    weeklyOrderId: '',
    amountUnitPerCu: '',
    amountCuPerTu: '',
    amountTuPerLu: '',
    productId: undefined,
    labelId: undefined,
    lu: 'PALETTE',
    tu: undefined,
    cu: undefined,
    orders: currentWeekDays(date).map((dayDate) => ({
      quantityTu: 0,
      pricePerCu: '0.00',
      date: format(dayDate, DOT_DATE_FORMAT)
    }))
  }
  return test
}

export function findExistingProductLabels(
  orderItems: TOrderItemForm[],
  productId?: string
) {
  return orderItems.reduce<string[]>(
    (result, item) => (
      item.productId === productId && item.labelId
        ? [...result, item.labelId]
        : result
    ),
    []
  )
}
