import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { apiClient } from '../../Interceptor'
import { TUpdateDailyGrowingPlanData, TDailyOrderData } from './types'
import { QuantityReportsQueryKeys } from '../QuantityReports/queries'
import { TWeeklyConfirmedParams, TWeeklyOrderDataItem } from '../WeeklyReport/types'

enum QueryKeys {
  OrdersDaily = 'OrdersDaily',
}

async function ordersDaily(params: Partial<TWeeklyConfirmedParams>): Promise<TDailyOrderData> {
  try {
    const response = await apiClient.get('/app/orders/weekly/confirmed', { params })
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useOrdersDailyQuery = (params: Partial<TWeeklyConfirmedParams>) =>
  useQuery<TDailyOrderData, Error>(
    [QueryKeys.OrdersDaily, params],
    () => ordersDaily(params),
    {
      enabled: !!(params.dateFrom && params.dateTo),
      cacheTime: 0
    }
  )

type TUpdateGrowingPlanMutationParams = {
  dailyOrderId: string,
  data: TUpdateDailyGrowingPlanData
}

async function updateDailyOrder(
  { dailyOrderId, data }: TUpdateGrowingPlanMutationParams
): Promise<TWeeklyOrderDataItem> {
  try {
    const res = await apiClient.put(`/app/orders/daily/${dailyOrderId}`, data)
    return res.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useUpdateDailyOrderMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<TWeeklyOrderDataItem, Error, TUpdateGrowingPlanMutationParams>(
    updateDailyOrder, {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(
          [QuantityReportsQueryKeys.OrdersCalendar]
        )
        await queryClient.invalidateQueries([QueryKeys.OrdersDaily])

        return data
      }
    }
  )
}

async function confirmDailyOrder(dailyOrderId: string): Promise<void> {
  try {
    await apiClient.put(`/app/orders/daily/${dailyOrderId}/confirm`)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useConfirmDailyOrderMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<void, Error, string>(confirmDailyOrder, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        [QuantityReportsQueryKeys.OrdersCalendar]
      )
      await queryClient.invalidateQueries([QueryKeys.OrdersDaily])
    }
  })
}

async function createDailyOrder(data: TUpdateDailyGrowingPlanData): Promise<TWeeklyOrderDataItem> {
  try {
    const res = await apiClient.post('/app/orders/daily', data)
    return res.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useCreateDailyOrderMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<TWeeklyOrderDataItem, Error, TUpdateDailyGrowingPlanData>(createDailyOrder, {
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(
        [QuantityReportsQueryKeys.OrdersCalendar]
      )

      await queryClient.invalidateQueries([QueryKeys.OrdersDaily])

      return data
    }
  })
}

type TCheckDailyOrderTimeMutationParams = {
  date: string,
  dailyOrderId: string,
  weeklyOrderId: string
}
