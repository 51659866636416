import { FC } from 'react'
import styles from './QuantityRemove.module.scss'
import DeleteBinIcon from '../../../../../shared/components/icons/DeleteBinIcon'
import { useBaseQuantityFormContext } from '../../state/BaseQuantityFormState'
import { useRemoveWeeklyDraftReport } from '../../queries'

type TProps = {
    quantityIndex: number
    weeklyOrderId: string
}
const QuantityRemove: FC<TProps> = ({ quantityIndex, weeklyOrderId }) => {
  const { remove } = useBaseQuantityFormContext()
  const { mutateAsync: removeDraftReportAsync } = useRemoveWeeklyDraftReport()

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    removeDraftReportAsync(weeklyOrderId).then(() => remove(quantityIndex))
  }

  return (
    <div className={styles.buttonWrap}>
      <button
        type="button"
        onClick={handleClick}
        className={styles.iconWrapper}
      >
        <DeleteBinIcon width={22} height={22}/>
      </button>
    </div>
  )
}

export default QuantityRemove
