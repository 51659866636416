import { FC, useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { format, parse } from 'date-fns'
import { UseFormReturn } from 'react-hook-form'
import PrimaryLayout from '../../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import { QuantityAccordion, TOrderItemForm } from '../../../BaseQuantityReports'
import styles from './DailyOrder.module.scss'
import { DOT_DATE_FORMAT } from '../../../../../shared/constants/date'
import { TUpdateDailyGrowingPlanData } from '../../types'
import {
  useConfirmDailyOrderMutation,
  useCreateDailyOrderMutation,
  useOrdersDailyQuery,
  useUpdateDailyOrderMutation
} from '../../queries'
import { TWeeklyOrderData, TWeeklyOrderDataItem } from '../../../WeeklyReport/types'
import {
  prepareFormItem,
  useBuyerId,
  useFormInit,
  useSellerId
} from '../../../WeeklyReport/components/WeeklyReport/WeeklyReport'
import { useDisabledProductModels } from '../../../BaseQuantityReports/hooks/useDisabledProductModels'
import Spinner from '../../../../../shared/components/Spinner/Spinner'
import { useNavigateToCalendar } from '../../../BaseQuantityReports/hooks/useNavigateToCalendar'
import DailyOrderHeader from '../DailyOrderHeader/DailyOrderHeader'
import { useWeeklyOrderParams } from '../../../QuantityReports/hooks/useWeeklyOrderParams'
import { TSubmitData } from '../../../BaseQuantityReports/types'
import { dateFromDotDateFormat, dotDateFormat } from '../../../../../shared/utils/date'
import useExpiredDateCheck from '../../../BaseQuantityReports/hooks/usePastDateCheck'

function prepareData(
  growingPlanId: string,
  date: string,
  data: TOrderItemForm,
  sellerId: string,
  buyerId: string
): TUpdateDailyGrowingPlanData {
  const dailyOrder = data.orders.find(({ date: orderDate }) => date === orderDate)
  return {
    growingPlanId,
    date,
    productId: data.productId as string,
    sellerId,
    buyerId,
    labelId: data.labelId as string,
    cu: data.cu as string,
    amountUnitPerCu: data.amountUnitPerCu as number,
    tu: data.tu as string,
    amountCuPerTu: data.amountCuPerTu as number,
    lu: data.lu as string,
    amountTuPerLu: data.amountTuPerLu as number,
    quantityTu: dailyOrder?.quantityTu as number,
    pricePerCu: dailyOrder?.pricePerCu as number

  }
}

function useGetGrowingPlanInfo(weeklyOrdersData: TWeeklyOrderData) {
  return useMemo(() => {
    if (!weeklyOrdersData) {
      return ''
    }
    const {
      growingPlanTitle,
      currentUserSeller,
      seller,
      buyer } = weeklyOrdersData

    return `${growingPlanTitle} (${currentUserSeller ? buyer.name : seller.name})`
  }, [weeklyOrdersData])
}

function useDayDateSearchParam() {
  const [searchParams] = useSearchParams()

  const dateString = searchParams.get('date')

  if (!dateString) {
    return undefined
  }

  return parse(
    dateString,
    DOT_DATE_FORMAT,
    new Date()
  )
}

function useOnSubmit(sellerId: string, buyerId: string, formReturn: UseFormReturn<any>) {
  const { mutateAsync: createMutateAsync } = useCreateDailyOrderMutation()
  const { mutateAsync: updateMutateAsync } = useUpdateDailyOrderMutation()

  const dayDateSearchParam = useDayDateSearchParam()
  const { growingPlanId } = useWeeklyOrderParams()

  return useCallback(({ data, orderId, quantityIndex }: TSubmitData) => {
    const onSuccess = (resData: TWeeklyOrderDataItem) => {
      if (dayDateSearchParam) formReturn?.setValue(`orderItems[${quantityIndex}]`, prepareFormItem(resData, dayDateSearchParam))
    }

    if (orderId && dayDateSearchParam) {
      updateMutateAsync({
        data: prepareData(
            growingPlanId as string, dotDateFormat(dayDateSearchParam),
            data,
            sellerId,
            buyerId
        ),
        dailyOrderId: orderId
      })

      return
    }

    if (dayDateSearchParam) {
      createMutateAsync(
        prepareData(
            growingPlanId as string, dotDateFormat(dayDateSearchParam),
            data,
            sellerId,
            buyerId
        )
      ).then(onSuccess)
    }
  }, [dayDateSearchParam])
}

function useOnConfirm() {
  const { mutateAsync: createMutateAsync } = useConfirmDailyOrderMutation()

  return useCallback((orderId?: string) => {
    if (orderId) {
      createMutateAsync(orderId)
    }
  }, [])
}

function useOnlyDay(fallback: string) {
  const dayDateSearchParam = useDayDateSearchParam()
  return dayDateSearchParam ? format(dayDateSearchParam, DOT_DATE_FORMAT) : fallback
}

type TProps = {
  weeklyOrdersData: TWeeklyOrderData,
  ordersDate: string
}
const DailyOrder: FC<TProps> = ({ weeklyOrdersData, ordersDate }) => {
  const date = useOnlyDay(ordersDate)
  const {
    useFormReturn,
    initialFormData,
    fieldArrayReturn
  } = useFormInit(dateFromDotDateFormat(date), weeklyOrdersData.orders)
  const sellerId = useSellerId()
  const buyerId = useBuyerId()

  const navigateToCalendar = useNavigateToCalendar(weeklyOrdersData.growingPlanId, dateFromDotDateFormat(date), sellerId || '', buyerId || '')

  const onSubmit = useOnSubmit(weeklyOrdersData.seller.id, weeklyOrdersData.buyer.id, useFormReturn)

  const onConfirm = useOnConfirm()

  const growingPlanInfo = useGetGrowingPlanInfo(weeklyOrdersData)
  const isAllowAddProduct = useExpiredDateCheck({ type: 'daily', date })

  return (
    <PrimaryLayout
      noPadding
      size="large"
      maxHeight
      header={(
        <DailyOrderHeader
          dateDailyOrder={date}
          growingPlanInfo={growingPlanInfo}
          goBack={navigateToCalendar}/>
      )}
    >
      <QuantityAccordion
        onlyDay={useOnlyDay(ordersDate)}
        fieldArrayReturn={fieldArrayReturn}
        disabledProductModels={useDisabledProductModels(weeklyOrdersData)}
        formReturn={useFormReturn}
        date={dateFromDotDateFormat(date)}
        isProductAddable={!weeklyOrdersData.currentUserSeller && isAllowAddProduct}
        isSeller={weeklyOrdersData.currentUserSeller}
        weeklySellerId={weeklyOrdersData?.seller.id}
        orders={weeklyOrdersData?.orders}
        initialFormData={initialFormData}
        onSubmit={onSubmit}
        onConfirm={onConfirm}
        buyerId={useBuyerId() ?? weeklyOrdersData?.buyer.id}
        sellerDeliveryDays={weeklyOrdersData.sellerDeliveryDays}
      />
      <div className={styles.line}/>
    </PrimaryLayout>
  )
}

export default () => {
  const params = useWeeklyOrderParams()
  const { data: weeklyOrdersData } = useOrdersDailyQuery(params)

  if (!weeklyOrdersData) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner/>
      </div>
    )
  }

  return (
    <DailyOrder
      weeklyOrdersData={weeklyOrdersData}
      ordersDate={params.dateFrom as string}
    />
  )
}
