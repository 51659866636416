import { TGrowingPlan } from '../../BaseGrowingPlanWorkShop'
import { TProductPlanForm } from '../../BaseGrowingPlanWorkShop/types'

export const setForm = (defaultProductPlan: TProductPlanForm, data?: TGrowingPlan) => {
  if (!data) {
    return undefined
  }
  const {
    customer,
    description,
    dailyOrderTime,
    year,
    title,
    productPlans: growingPlanProducts
  } = data
  const [hour, minute] = dailyOrderTime.split(':')
  const date = new Date()
  date.setHours(Number(hour), Number(minute))

  return {
    customerId: customer.id,
    description,
    dailyOrderTime: date,
    year,
    title,
    productsPlan: growingPlanProducts.map(({
      unit,
      product,
      values,
      ordersExist
    }) => ({
      isPlanOrderExist: ordersExist,
      unit,
      productId: product.id,
      values: values.filter((value) => value.label?.status !== 'DEACTIVATED').sort((a, b) => {
        const defaultValueIndex = defaultProductPlan.values.findIndex(
          (value) => value.labelId === a.label?.id
        )
        const currentValueIndex = defaultProductPlan.values.findIndex(
          (value) => value.labelId === b.label?.id
        )
        return defaultValueIndex - currentValueIndex
      })
    }))
  }
}
