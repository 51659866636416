import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { format } from 'date-fns'
import { useMemo } from 'react'
import { apiClient } from '../../Interceptor'
import { DOT_DATE_FORMAT } from '../../../shared/constants/date'
import { TOrdersEstimatesData, TProductData } from './types'
import { currentWeekDays } from './utils/generateFormData'
import { QuantityReportsQueryKeys } from '../QuantityReports/queries'

enum QueryKeys {
    GrowingPlansProducts = 'GrowingPlansProducts',
    OrdersEstimates = 'OrdersEstimates',
}

async function products(
  growingPlanId?: string,
  sellerId?: string,
  date?: Date,
  buyerId?: string,
  onlyDay?: string
): Promise<TProductData> {
  try {
    const response = await apiClient.get('/app/orders/weekly/products', {
      params: {
        growingPlanId,
        sellerId,
        weekFirstDate: format(currentWeekDays(date)[0], DOT_DATE_FORMAT),
        entryPoint: onlyDay ? 'DAILY' : 'WEEKLY',
        buyerId
      }
    })
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useProductsQuery = (
  growingPlanId?: string | null,
  sellerId?: string, date?: Date,
  buyerId?: string,
  onlyDay?: string
) => {
  const key = [QueryKeys.GrowingPlansProducts, growingPlanId]

  return useQuery<TProductData, Error>(
    [key],
    () => products(growingPlanId!, sellerId, date, buyerId, onlyDay),
    {
      enabled: !!growingPlanId || !!sellerId
    }
  )
}

async function ordersEstimates(
  growingPlanId: string,
  dates: string[],
  productId?: string,
  labelId?: string,
  prepareSellerId?: string
): Promise<TOrdersEstimatesData> {
  try {
    const response = await apiClient.get(
      `/app/orders/estimates?${dates.map((date) => `dates=${date}`).join('&')}`, {
        params: {
          growingPlanId,
          productId,
          labelId,
          sellerId: prepareSellerId
        }
      }
    )
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useOrdersEstimatesQuery = (
  dates: Date[],
  growingPlanId?: string | null,
  productId?: string,
  label?: string,
  prepareSellerId?: string
) => {
  const dateStrings = useMemo(() => dates.map((date) => format(date, DOT_DATE_FORMAT)), [dates])

  const key = [
    QueryKeys.OrdersEstimates,
    growingPlanId,
    dateStrings,
    productId,
    label,
    prepareSellerId
  ]
  return useQuery<TOrdersEstimatesData, Error>(
    [key],
    () => ordersEstimates(growingPlanId!, dateStrings, productId, label, prepareSellerId),
    {
      enabled: !!productId && !!label && (!!growingPlanId || !!prepareSellerId)
    }
  )
}

const removeWeeklyDraftReport = async (weeklyOrderId: string) => {
  try {
    await apiClient.delete(`/app/orders/weekly/${weeklyOrderId}`)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useRemoveWeeklyDraftReport = () => {
  const queryClient = useQueryClient()
  return useMutation<void, Error, string>(
    {
      mutationFn: removeWeeklyDraftReport,
      onSuccess: () => {
        queryClient.invalidateQueries([QuantityReportsQueryKeys.OrdersCalendar])
        queryClient.invalidateQueries([QuantityReportsQueryKeys.OrdersWeekly])
      }
    }
  )
}
