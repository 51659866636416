import { useMemo } from 'react'
import { useBaseQuantityFormContext } from '../state/BaseQuantityFormState'
import { useProductsQuery } from '../queries'
import { useSellerId } from './useSellerId'
import { combineGrowingPlanProducts } from '../utils/prepareGrowingPlanProductData'

export function useGetUnit(quantityIndex: number) {
  const {
    onlyDay,
    buyerId,
    growingPlanId,
    date,
    formReturn: { watch }
  } = useBaseQuantityFormContext()
  const preparedSellerId = useSellerId()

  const { data } = useProductsQuery(growingPlanId, preparedSellerId, date, buyerId, onlyDay)

  const productId = watch(`orderItems.[${quantityIndex}].productId`)

  return useMemo(() => {
    if (!productId || !data) {
      return '-'
    }

    const unit = combineGrowingPlanProducts(data)
      .find((product) => product.productId === productId)?.unit

    return unit === 'PCS' ? 'pcs' : 'kg'
  }, [data, productId])
}
